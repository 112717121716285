import { useLocation, useNavigate, useSearchParams } from '@solidjs/router';
import { For, Match, Show, Switch, onMount, useContext } from 'solid-js';
import { AppContext } from '../../app-context-provider/app-context-provider';
import {
    changeSelectedEventCategory,
    changeSelectedEventLanguage,
    changeSelectedEventTag,
    clearFilters,
    eventsStore,
    setLandedWithSearchParams,
    setStatus,
    setAreaOfCare,
    setAvailableLanguages,
    AreaOfCare,
    EventStatus,
} from '../../stores/events-store';
import theme from '../../style/theme';
import { ErrorCatcher } from '../../tools/error-catcher';
import { FilterList } from '../../ui-components/filter-list/filter-list';
import { Heading } from '../../ui-components/heading/heading';
import { Checkbox } from '../../ui-components/inputs/checkbox/checkbox';
import { Radio } from '../../ui-components/inputs/radio/radio';
import { StyledSidebar, StyledSidebarContainer, StyledSidebarContainerLine, StyledSidebarInner } from '../../ui-components/layouts/sidebar.style';
import { Text } from '../../ui-components/text/text';
import { StyledClearAllContainer, StyledFilterHeadingContainer, StyledHeadingWrapper } from '../products/products.style';
import { EventsSidebarProps } from './events-types';
import { EventsSidebarMobile } from './mobile/events-sidebar-mobile';
import { Button } from '../../ui-components/button/button';
import { getUniqueLanguages } from './helpers/languages-helper';
import { statusNameToStatusTranslated } from './helpers/translations-helper';

export const EventsSidebar = (props: EventsSidebarProps) => {
    const location = useLocation();
    const [searchParams] = useSearchParams();
    const { viewport, localize } = useContext(AppContext);

    const navigate = useNavigate();

    const isMobile = () => viewport.width <= theme.breakpoints.MOBILE;

    const {
        handleOnClickStatus,
        handleOnClickCategory,
        handleOnClickTag,
        handleOnClickLanguage,
        handleOnClickAreaOfCare,
    } = props.clickHelper;


    onMount(() => {
        clearFilters();
        let landedWithSearchParams = false;

        if (searchParams.status) {
            landedWithSearchParams = true;
            const status = eventsStore.availableStatuses.find((item) => item.slug === searchParams.status);
            status && setStatus(status);
        }

        if (searchParams.languages) {
            landedWithSearchParams = true;
            const allLanguages = searchParams.languages.split(',');
            const extractedUniqueLanguages = getUniqueLanguages(props.events);
            setAvailableLanguages(extractedUniqueLanguages);

            allLanguages.forEach((selectedLanguage: string) => {
                const language = eventsStore?.availableLanguages.find((language) => language === selectedLanguage);
                
                language && changeSelectedEventLanguage(selectedLanguage);
            });
        }

        if (searchParams.categories) {
            landedWithSearchParams = true;
            const allCategories = searchParams.categories.split(',');
            allCategories.forEach((selectedCategory: string) => {
                const category = eventsStore?.availableCategories.find((category) => category.slug === selectedCategory);
                category && changeSelectedEventCategory(category);
            });
        }

        if (searchParams.tags) {
            landedWithSearchParams = true;
            const allTags = searchParams.tags.split(',');
            allTags.forEach((selectedTag: string) => {
                const tag = eventsStore?.availableTags.find((tag) => tag.slug === selectedTag);
                tag && changeSelectedEventTag(tag);
            });
        }

        if (searchParams.areaOfCare) {
            landedWithSearchParams = true;
            const areaOfCare = eventsStore.availableAreaOfCares.find((item) => item.slug === searchParams.areaOfCare);
            areaOfCare && setAreaOfCare(areaOfCare);
        }

        setLandedWithSearchParams(landedWithSearchParams);
    });

    const handleOnClickClearFilters = (e: any) => {
        e.preventDefault();
        clearFilters();
        navigate(location.pathname + (searchParams.status ? `?status=${searchParams.status}` : ''), { scroll: false });
    };

    const isAreaOfCareChecked = (areaOfCare: AreaOfCare) => {
        return eventsStore.areaOfCare?.slug === areaOfCare.slug || (eventsStore.areaOfCare.slug === '' && areaOfCare.slug === 'all');
    };

    const orderedFiltrationStatuses = (a: EventStatus, b: EventStatus) => {
        const order = ['upcoming', 'past', 'on-demand', 'all'];
        return order.indexOf(a.slug) - order.indexOf(b.slug);
    };

    return (
        <ErrorCatcher componentName="Events sidebar">
            <StyledSidebarContainer>
                <StyledSidebarContainerLine>
                    <StyledSidebar>
                        <StyledSidebarInner>
                            <StyledHeadingWrapper>
                                <Heading tag="h1" variant={'giantRed'}>
                                    {props?.labels?.pageHeading || 'All events'}
                                </Heading>
                            </StyledHeadingWrapper>

                            <Show when={props.events?.length}>
                                <Show 
                                    when={!isMobile()}
                                    fallback={(
                                        <EventsSidebarMobile
                                            labels={props?.labels}
                                            clickHelper={props.clickHelper}
                                        />
                                    )}>
                                    <StyledFilterHeadingContainer>
                                        <Heading tag="h2" variant="medium" noBlockSpacing={true}>
                                            {localize('filters', 'Filters')}
                                        </Heading>
                                    </StyledFilterHeadingContainer>

                                    <FilterList listHeading={localize('status', 'Status')}>
                                        <For each={eventsStore.availableStatuses.toSorted(orderedFiltrationStatuses)}>
                                            {(status: any) => (
                                                <li onClick={() => handleOnClickStatus(status.slug)}>
                                                    <Radio
                                                        id={`status-${status.slug}`}
                                                        value={status.slug}
                                                        name='status'
                                                        readableName={statusNameToStatusTranslated(status.name, localize, props.labels)}
                                                        isChecked={eventsStore.status?.slug === status.slug}
                                                    />
                                                </li>
                                            )}
                                        </For>
                                    </FilterList>

                                    <FilterList listHeading={localize('area-of-care', 'Area Of Care')}>
                                        <For each={eventsStore.availableAreaOfCares}>
                                            {(areaOfCare: AreaOfCare) => (
                                                <li onClick={() => handleOnClickAreaOfCare(areaOfCare.slug)}>
                                                    <Radio
                                                        id={`areaOfCare-${areaOfCare.slug}`}
                                                        value={areaOfCare.slug}
                                                        name='areaOfCare'
                                                        readableName={statusNameToStatusTranslated(areaOfCare.name, localize, props.labels)}
                                                        isChecked={isAreaOfCareChecked(areaOfCare)}
                                                    />
                                                </li>
                                            )}
                                        </For>
                                    </FilterList>

                                    <Show when={props.isInternationalSite}>
                                        <FilterList listHeading={localize('languages', 'Languages')}>
                                            <Show
                                                when={eventsStore.availableLanguages.length > 0}
                                                fallback={
                                                    <li>
                                                        <Text fontSize="small" displayRedVerticalLine={false}>
                                                            {props?.labels?.noLanguagesFound || 'No languages found'}
                                                        </Text>
                                                    </li>
                                                }
                                            >
                                                <For each={eventsStore.availableLanguages}>
                                                    {(language: string) => (
                                                        <li>
                                                            <Checkbox
                                                                value={language}
                                                                whenClicked={() => handleOnClickLanguage(language)}
                                                                name={language}
                                                                isChecked={!!eventsStore?.selectedLanguages?.find((existing) => existing === language)}
                                                            />
                                                        </li>
                                                    )}
                                                </For>
                                            </Show>
                                        </FilterList>
                                    </Show>

                                    <FilterList listHeading={localize('categories', 'Categories')}>
                                        <Switch>
                                            <Match when={eventsStore.availableCategories.length === 0}>
                                                <li>
                                                    <Text fontSize="small" displayRedVerticalLine={false}>
                                                        {props?.labels?.noCategoriesFound || 'No categories found'}
                                                    </Text>
                                                </li>
                                            </Match>
                                            <Match
                                                when={
                                                    eventsStore.selectedLanguages.length === 0 &&
                                                    eventsStore.availableCategories.length &&
                                                    props.isInternationalSite
                                                }
                                            >
                                                <li>
                                                    <Text fontSize="small" displayRedVerticalLine={false}>
                                                        {props?.labels?.noLanguageSelected || 'No language selected'}
                                                    </Text>
                                                </li>
                                            </Match>
                                            <Match
                                                when={
                                                    (eventsStore.selectedLanguages.length > 0 && props.isInternationalSite) ||
                                                    eventsStore.availableCategories.length
                                                }
                                            >
                                                <For each={eventsStore.availableCategories}>
                                                    {(category) => (
                                                        <li>
                                                            <Checkbox
                                                                value={category?.slug}
                                                                whenClicked={() => handleOnClickCategory(category)}
                                                                name={category?.name}
                                                                isChecked={
                                                                    !!eventsStore?.selectedCategories?.find((existing) => existing?.slug === category?.slug)
                                                                }
                                                            />
                                                        </li>
                                                    )}
                                                </For>
                                            </Match>
                                        </Switch>
                                    </FilterList>

                                    <FilterList listHeading={localize('tags', 'Tags')}>
                                        <Switch>
                                            <Match when={eventsStore.availableTags.length === 0}>
                                                <li>
                                                    <Text fontSize="small" displayRedVerticalLine={false}>
                                                        {props?.labels?.noTagsFound || 'No tags found'}
                                                    </Text>
                                                </li>
                                            </Match>
                                            <Match
                                                when={
                                                    eventsStore.selectedLanguages.length === 0 &&
                                                    eventsStore.availableTags.length &&
                                                    props.isInternationalSite
                                                }
                                            >
                                                <Text fontSize="small" displayRedVerticalLine={false}>
                                                    <li>
                                                        {props?.labels?.noLanguageSelected || 'No language selected'}
                                                    </li>
                                                </Text>
                                            </Match>
                                            <Match
                                                when={
                                                    (eventsStore.selectedLanguages.length > 0 && props.isInternationalSite) ||
                                                    eventsStore.availableTags.length
                                                }
                                            >
                                                <For each={eventsStore.availableTags}>
                                                    {(tag) => (
                                                        <li>
                                                            <Checkbox
                                                                value={tag?.slug}
                                                                whenClicked={() => handleOnClickTag(tag)}
                                                                name={tag?.name}
                                                                isChecked={!!eventsStore?.selectedTags?.find((existing) => existing?.slug === tag?.slug)}
                                                            />
                                                        </li>
                                                    )}
                                                </For>
                                            </Match>
                                        </Switch>
                                    </FilterList>
                                </Show>
                                <Show when={eventsStore.selectedLanguages.length || eventsStore.selectedCategories.length || eventsStore.selectedTags.length}>
                                    <StyledClearAllContainer>
                                        <Button 
                                            label={localize('clear-all', 'Clear all')}
                                            onClick={(e) => handleOnClickClearFilters(e)}
                                            variant='tertiary'
                                            noCaps={true}
                                        />
                                    </StyledClearAllContainer>
                                </Show>
                            </Show>
                        </StyledSidebarInner>
                    </StyledSidebar>
                </StyledSidebarContainerLine>
            </StyledSidebarContainer>
        </ErrorCatcher>
    );
};
