import { useLocation } from '@solidjs/router';
import { gql } from 'graphql-request';
import { createSignal, onMount, Show, useContext } from 'solid-js';
import { AppContext } from '../../app-context-provider/app-context-provider';
import { Section } from '../../grid-system/section/section';
import { StyledErrorMessage } from '../login-form/login-form-styles';
import { StyledVerifyAccount,StyledSignIn, StyledVerifyAccountText } from './verify-account.style';

const verifyAccountMutation = gql`
    mutation verifyAccount($token: String!) {
        verifyAccount(
            token: $token
        ) {
            status
            message
        }
    }
`;

export const VerifyAccount = () => {
    const { menuStore, graphQlClient } = useContext(AppContext);
    const [verifySuccess, setVerifySuccess] = createSignal(false);
    const [error,setError] = createSignal('');
    const { token } = useLocation().query;
    const { openLoginMenu } = menuStore;

    onMount(async () => {
        if(token) {
            const {verifyAccount: {status,message}} = await graphQlClient(
                verifyAccountMutation,
                { token },
                false
            );
            if(status !== 'OK') {
                setError(message);
            } else {
                setVerifySuccess(true);
            }
        }
    });
    
    return (
        <Section widthType="bgFull" templateShorthand={[12]}>
            <Show when={verifySuccess()} fallback={(
                <StyledErrorMessage>
                    {error()}
                </StyledErrorMessage>
            )}>
                <StyledVerifyAccount>
                    <StyledVerifyAccountText>
                        Thank you for verifying your account. You can now 
                        <StyledSignIn onClick={() => openLoginMenu()}>sign in.</StyledSignIn>
                    </StyledVerifyAccountText>
                </StyledVerifyAccount>
            </Show>
        </Section>
    );
};
