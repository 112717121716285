import { useContext, createSignal, For, Match, Switch } from 'solid-js';
import { Show } from 'solid-js';
import { Link } from '../../../../../ui-components/link/link';
import type { ShortCutMenuLinkItem, ShortcutsMenuDesktopProps, ShortcutsMenuProps } from '../../../types/shortcuts-types';
import { AngleDownIcon } from '../../../../icons-library/angle-down';
import { AngleUpIcon } from '../../../../icons-library/angle-up';
import { ShortcutMenuIcons } from './shortcuts-menu-icons/shortcuts-menu-icons';
import {
    StyledShortcutsMenu,
    StyledShortcutsMenuLinkItem,
    StyledShortcutsMenuLinkItems,
    StyledShortcutsMenuHeading,
    StyledAngleDownIconContainer,
    StyledAngleUpIconContainer,
    StyledShortcutsMenuButton,
} from './shortcuts-menu.styles';
import { StyledUtilityLink } from '../../../../../ui-components/link/link.styles';
import removeAmpersand from '../../../../../tools/remove-ampersand';
import { AppContext } from '../../../../../app-context-provider/app-context-provider';

export const ShortcutsMenu = (props: ShortcutsMenuProps & ShortcutsMenuDesktopProps) => {

    const { menuStore } = useContext(AppContext);
    const [open, setOpen] = createSignal(false);
    const { close } = menuStore;

    return (
        <Show when={props.links?.length > 0}>
            <StyledShortcutsMenu>
                <StyledShortcutsMenuButton
                    onClick={() => setOpen(!open())}
                    ref={(el: HTMLElement) => {
                        if (props.firstElement) {
                            props.firstElement(el);
                        }
                    }}
                >
                    <StyledShortcutsMenuHeading>
                        {props.menuHeading}
                    </StyledShortcutsMenuHeading>
                    <Switch>
                        <Match when={!open()}>
                            <StyledAngleDownIconContainer>
                                <AngleDownIcon fill="white" width={1.43} />
                            </StyledAngleDownIconContainer>
                        </Match>
                        <Match when={open()}>
                            <StyledAngleUpIconContainer>
                                <AngleUpIcon fill="white" width={1.43} />
                            </StyledAngleUpIconContainer>
                        </Match>
                    </Switch>
                </StyledShortcutsMenuButton>
                <Show when={open()}>
                    <nav>
                        <StyledShortcutsMenuLinkItems>
                            <For each={props.links}>
                                {(link: ShortCutMenuLinkItem) => (
                                    <Show when={link.url}>
                                        <StyledShortcutsMenuLinkItem onClick={() => close()}>
                                            <Show when={link.icon}>
                                                <ShortcutMenuIcons name={link.icon} />
                                            </Show>
                                            <Show
                                                when={link.icon !== 'phone'}
                                                fallback={
                                                    <StyledUtilityLink
                                                        href={link.url}
                                                        darkMode={true}
                                                    >
                                                        {removeAmpersand(link.title)}
                                                    </StyledUtilityLink>
                                                }
                                            >
                                                <Link url={link.url} label={removeAmpersand(link.title)} darkMode={true} />
                                            </Show>
                                        </StyledShortcutsMenuLinkItem>
                                    </Show>
                                )}
                            </For>
                        </StyledShortcutsMenuLinkItems>
                    </nav>
                </Show>
            </StyledShortcutsMenu>
        </Show>
    );
};
