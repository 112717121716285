import { createSignal, onCleanup, onMount, Show, useContext } from 'solid-js';
import { AppContext } from '../../app-context-provider/app-context-provider';
import { Button } from '../../ui-components/button/button';
import { Link } from '../../ui-components/link/link';
import { StyledErrorMessage, StyledLoginForm, StyledLoginFormContent, StyledLoginInput } from './login-form-styles';
import { useLocation, useNavigate } from '@solidjs/router';
import { logIn } from '../../tools/login-user';
import { StyledFlexRow } from '../../ui-components/utility-style-components/flex';
import { LoadingSpinner } from '../loading-spinner/loading-spinner';

export const LoginForm = () => {
    const { menuStore, pageRegistration, localize, pageRequestPasswordResetUrl, siteInfo, updateUser, recaptchaSiteKey } = useContext(AppContext);
    const { closeLoginMenu } = menuStore;
    const [emailValue, setEmailValue] = createSignal('');
    const [passwordValue, setPasswordValue] = createSignal('');
    const [errorMessage, setErrorMessage] = createSignal('');
    const [signingIn, setSigningIn] = createSignal(false);
    const navigate = useNavigate();
    const location = useLocation();

    onMount(() => {
        const handleKeyPress = (e: any) => {
            if (e.keyCode === 13 && emailValue() !== '' && passwordValue() !== '') {
                handleLogin();
            }
        };

        document.addEventListener('keypress', handleKeyPress);

        if (!document.querySelector('#grecaptcha')) {
            const scriptTag = document.createElement('script');
            scriptTag.type = 'text/javascript';
            scriptTag.id = 'grecaptcha';
            scriptTag.src = `https://www.google.com/recaptcha/enterprise.js?render=${recaptchaSiteKey}`;
            document.head.appendChild(scriptTag);
        }

        onCleanup(() => document.removeEventListener('keypress', handleKeyPress));
    });

    const handleLogin = async () => {
        const args = {
            email: emailValue(),
            password: passwordValue(),
            siteType: siteInfo.siteType,
            siteSlug: siteInfo.siteSlug,
        };

        setSigningIn(true);
        const { success, message } = await logIn(args, updateUser, recaptchaSiteKey);
        setSigningIn(false);

        if (!success) {
            if (message === 'invalid-credentials') {
                setErrorMessage(localize('invalid-credentials', 'Invalid credentials'));
            } else {
                setErrorMessage(localize('error-has-occurred', 'An error has occurred, please try again later'));
            }

            return;
        }

        closeLoginMenu();
    };

    return (
        <StyledLoginForm>
            <StyledLoginFormContent>
                <StyledLoginInput
                    value={emailValue()}
                    onInput={(e) => setEmailValue(e.currentTarget.value)}
                    placeholder={localize('email' ,'Email')}
                    type="email"
                />
                <StyledLoginInput
                    value={passwordValue()}
                    onInput={(e) => setPasswordValue(e.currentTarget.value)}
                    placeholder={localize('password' ,'Password')} type="password"
                />
                <Show when={errorMessage()} fallback={<div style={{height: '22px'}}></div>}>
                    <StyledErrorMessage>
                        {errorMessage()}
                    </StyledErrorMessage>
                </Show>
                <StyledFlexRow gap='1.5rem'>
                    <Button
                        label={localize('sign-in' ,'Sign in')} 
                        onClick={() => handleLogin()}
                        disabled={signingIn() || emailValue() === '' || passwordValue() === ''}
                    />
                    <Show when={signingIn()}>
                        <LoadingSpinner />
                    </Show>
                </StyledFlexRow>
                <Link
                    label={localize('reset-password' ,'Reset password')}
                    url={pageRequestPasswordResetUrl}
                    onClick={() => closeLoginMenu()}
                />
                <Link
                    asButton={true}
                    label={localize('register-a-new-user-account' ,'Register a new user account')}
                    url={'/'}
                    onClick={() => {
                        navigate(pageRegistration.url, { state: { fromUrl: location.pathname }} );
                        closeLoginMenu();
                    }}
                />
            </StyledLoginFormContent>
        </StyledLoginForm>
    );
};
export type LoginFormProps = {};
