import { styled } from 'solid-styled-components';
import { headerHeight } from '../../components/page/page.styles';
import theme from '../../style/theme';

export const StyledModalWrapper = styled.div<{ darkenBackground?: boolean }>`
    container-type: inline-size;
    position: fixed;
    overflow: scroll;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding-top: ${headerHeight};
    z-index: 10;
    background: ${(p) => p.darkenBackground ? 'rgba(238,238,238,0.7)' : 'transparent'};

    ${theme.mediaQueries.mobileMax}{
        padding-top: 0;
    }

    ::-webkit-scrollbar {
        display: none;
    }
`;

export const StyledModalContent = styled.div<{ size: undefined | 'small' | 'normal' | 'large' }>`
    background-color: white;
    max-width: ${(p) => {
        if (p.size === 'small') return '44rem';
        if (p.size === 'normal') return '64rem';
        return '100%'; 
    }};
    width: 100%;
    margin: 0 auto;
    border-radius: 1.43rem !important;
    box-shadow: 0 0.21rem 0.36rem 0 rgba(0, 0, 0, 0.1), 0 0.36rem 0.71rem 0 rgba(0, 0, 0, 0.1);
    height: calc(100vh - 5rem);
`;

export const StyledModalHeader = styled.div`
    display: flex;
    justify-content: flex-end;
    & > button {
        font-size: 1.5rem;
        border: 0;
        background: none;
        cursor: pointer;
    }
`;

export const StyledModalBody = styled.div`
    text-align: center;
    padding: 3rem;
    padding-bottom: 6rem;
    height: auto;
    overflow-y: auto;

    ${theme.mediaQueries.mobileMax}{
        max-height: calc(100vh - 5rem);
    }
`;

export const StyledModalFooter = styled.div`
    padding: 1.14rem 1.71rem;
    display: flex;
    justify-content: flex-end;
    gap: 0.5rem;

    button {
        cursor: pointer;
    }
`;
