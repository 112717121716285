import { gql } from 'graphql-request';
import { Match, Show, Switch, onCleanup, onMount, useContext } from 'solid-js';
import { isServer } from 'solid-js/web';
import { AppContext } from '../../app-context-provider/app-context-provider';
import { StyledMenu } from './menu.styles';
import type { ShortcutsData } from './types/shortcuts-types';
import type { HeaderData } from './menu-header-types';
import type { PrimaryMenuData } from './types/primary-menu-types';
import { MenuDesktop } from './menu-desktop/menu-desktop';
import { MenuMobile } from './menu-mobile/menu-mobile';
import theme from '../../style/theme';
import { ErrorCatcher } from '../../tools/error-catcher';

const GET_HEADER_QUERY = gql`
    query GetHeader($key: String!) {
        header: resource(key: $key) {
            content
            key
        }
    }
`;

const GET_SHORTCUTS_QUERY = gql`
    query GetShortcuts($key: String!) {
        shortcuts: resource(key: $key) {
            content
            key
        }
    }
`;

export const GET_PRIMARY_MENU_QUERY = gql`
    query GetPrimaryMenu($key: String!) {
        menu: resource(key: $key) {
            content
            key
        }
    }
`;

export const Menu = () => {

    const AppState = useContext(AppContext);
    const { createCachedResource, menuStore } = useContext(AppContext);


    const getShortcutsLocation = () => {
        const isHcpPage = menuStore.isHcp();
        const shortcutsLocation = isHcpPage ? 'shortcuts_hcp' : 'shortcuts';
        return shortcutsLocation;
    };

    const getMenuLocation = () => {
        const isHcpPage = menuStore.isHcp();
        const menuLocation = isHcpPage ? 'primary_menu_hcp' : 'primary_menu';
        return menuLocation;
    };

    const [headerContent] = createCachedResource(GET_HEADER_QUERY, () => ({ key: '/wp-json/rawb/v1/header' }), true);
    const [shortcutsContent] = createCachedResource(GET_SHORTCUTS_QUERY, () => ({ key: `/wp-json/rawb/v1/${getShortcutsLocation()}` }), true);
    const [primaryMenuContent] = createCachedResource(GET_PRIMARY_MENU_QUERY, () => ({ key: `/wp-json/content/v1/menus/${getMenuLocation()}` }), true);
    const { menuOpen, close } = menuStore;

    const loaded = () => {
        if (headerContent()) {
            return true;
        }
        return false;
    };

    const escapeListener = (e: KeyboardEvent) => {
        if (e.key === 'Escape') {
            close();
        }
    };

    onMount(() => {
        //close menu on escape
        document.addEventListener('keydown', escapeListener);
    });

    onCleanup(() => {
        !isServer && document.removeEventListener('keydown', escapeListener);
    });

    const shortcutsData = (): ShortcutsData => shortcutsContent()?.shortcuts?.content;
    const headerData = (): HeaderData => headerContent()?.header?.content;
    const primaryMenuData = (): PrimaryMenuData => primaryMenuContent()?.menu?.content;

    return (
        <ErrorCatcher componentName='Menu'>
            <Show when={menuOpen()}>
                <StyledMenu>
                    <Show when={loaded()} fallback={<h2>Could not find content</h2>}>
                        <Switch>
                            <Match when={AppState.viewport.width > theme.breakpoints.ORIGINAL_TABLET}>
                                <MenuDesktop
                                    shortcutsHeading={shortcutsData()?.shortcutsHeading}
                                    contactHeading={shortcutsData()?.contactHeading}
                                    letUsContactYouLink={shortcutsData()?.letUsContactYouLink}
                                    phoneNumber={shortcutsData()?.phoneNumber}
                                    servicesHeading={shortcutsData()?.servicesHeading}
                                    servicesLinks={shortcutsData()?.servicesLinks}
                                    headerLinks={headerData()?.headerLinks}
                                    handleCloseMenuClick={() => close()}
                                    primaryMenuLinks={primaryMenuData()?.menu}
                                    primaryMenuName={primaryMenuData()?.menuName}
                                />
                            </Match>
                            <Match when={AppState.viewport.width <= theme.breakpoints.ORIGINAL_TABLET}>
                                <MenuMobile
                                    shortcutsHeading={shortcutsData()?.shortcutsHeading}
                                    contactHeading={shortcutsData()?.contactHeading}
                                    letUsContactYouLink={shortcutsData()?.letUsContactYouLink}
                                    phoneNumber={shortcutsData()?.phoneNumber}
                                    servicesHeading={shortcutsData()?.servicesHeading}
                                    servicesLinks={shortcutsData()?.servicesLinks}
                                    headerLinks={headerData()?.headerLinks}
                                    handleCloseMenuClick={() => close()}
                                    primaryMenuLinks={primaryMenuData()?.menu}
                                    primaryMenuName={primaryMenuData()?.menuName}
                                />
                            </Match>
                        </Switch>
                    </Show>
                </StyledMenu>
            </Show>
        </ErrorCatcher>
    );
};
