/* eslint-disable indent */
import { Link } from '@solidjs/router';
import clsx from 'clsx';
import { Show, createEffect, createSignal, useContext } from 'solid-js';
import { AppContext } from '../../../app-context-provider/app-context-provider';
import theme from '../../../style/theme';
import { Button } from '../../../ui-components/button/button';
import { Heading } from '../../../ui-components/heading/heading';
import { MagnifyingGlassIcon } from '../../icons-library/magnifying-glass';
import { MenuBarsIcon } from '../../icons-library/menu-bars';
import { ShoppingBasketIcon } from '../../icons-library/shopping-basket';
import { LoginControls } from '../../login-controls/login-controls';
import { StyledBasketBadge, StyledCareNavigationShortcuts, StyledCircle, StyledIcon, StyledShoppingBasketContainer } from './header-atos-care.style';
import urlMatches from '../../../tools/url-match';
import removeAmpersand from '../../../tools/remove-ampersand';
import { BasketContext } from '../../basket/basket-context-provider';

export const HeaderAtosCareNavigationShortcuts = () => {

    const { menuStore, viewport, pageRegistration, shop, searchPage, contactUsPage, localize } = useContext(AppContext);
    const { basket } = useContext(BasketContext);
    const { close, open } = menuStore;
    const isOriginalTablet = () => viewport.width <= theme.breakpoints.ORIGINAL_TABLET;
    const isTablet = () => viewport.width <= theme.breakpoints.TABLET;
    const [animate, setAnimate] = createSignal(false);

    const itemsInBasket = () => {
        const totalItems = basket.order.orderDetails.reduce((acc, orderDetail) => {            
            return acc + orderDetail.amount;
        }, 0);
        
        return totalItems;
    };

    createEffect(() => {
        // Whenever the basket is updated, animate the basket icon
        if (itemsInBasket() > 0) {            
            setAnimate(true);
            setTimeout(() => {
                setAnimate(false);
            }, 250);
        }
    });

    return (
        <StyledCareNavigationShortcuts>
            <Show when={pageRegistration.url && pageRegistration.url !== ''}>
                <StyledIcon active={null} class={clsx( '!p-0', { 'border-r border-solid border-gray-dark': !isTablet() })}>
                    <LoginControls usePadding={true} showLoginRegisterLabel={!isOriginalTablet()} />
                </StyledIcon>
            </Show>

            <Show when={shop.basketPage.url && shop.basketPage.url !== ''}>
                <Link aria-label={'basket'} href={shop.basketPage.url} class="flex items-center">
                    <StyledIcon active={urlMatches(shop.basketPage?.url)} class={clsx({ 'border-r border-solid border-gray-dark': !isTablet() })}>
                        <StyledShoppingBasketContainer>
                            <ShoppingBasketIcon
                                {...(viewport.width < theme.breakpoints.MOBILE
                                    ? {
                                        height: 2.5,
                                        width: 2.5,
                                        opacity: 0.7,
                                    }
                                    : {})}
                            />
                            <Show when={basket.order.orderDetails.length > 0}>
                                <StyledBasketBadge>
                                    <StyledCircle animate={animate()} />
                                    <text x="25" y="14" text-anchor="middle" fill="white" font-size="10px" dy=".3em">
                                        {itemsInBasket()}
                                    </text>
                                </StyledBasketBadge>
                            </Show>
                        </StyledShoppingBasketContainer>
                        <Show when={!isOriginalTablet()}>
                            <span>{removeAmpersand(shop.basketPage.title)}</span>
                        </Show>
                    </StyledIcon>
                </Link>
            </Show>

            <Show when={searchPage.url && searchPage.url !== ''}>
                <StyledIcon active={urlMatches(searchPage.url)} class={clsx({ 'border-solid border-gray-dark': !isTablet() })} onClick={close}>
                    <Link href={searchPage.url} class="flex items-center">
                        <MagnifyingGlassIcon
                            {...(viewport.width < theme.breakpoints.MOBILE
                                ? {
                                    height: 2.5,
                                    width: 2.5,
                                    opacity: 0.7,
                                }
                                : {})}
                        />
                        <Show when={!isOriginalTablet()}>
                            <span>{removeAmpersand(searchPage.title)}</span>
                        </Show>
                    </Link>
                </StyledIcon>
            </Show>

            <Show
                when={!isOriginalTablet()}
                fallback={
                    <StyledIcon active={false} onClick={open}>
                        <MenuBarsIcon
                            width={3.67}
                            {...(viewport.width <= theme.breakpoints.MOBILE
                                ? {
                                    height: 2.27,
                                }
                                : {})}
                        />
                        <Show when={!isOriginalTablet()}>
                            <Heading tag="h3" variant="medium" noBlockSpacing={true}>
                                {localize('menu', 'Menu')}
                            </Heading>
                        </Show>
                    </StyledIcon>
                }
            >
                <Show when={contactUsPage.url && contactUsPage.url !== ''}>
                    <Button
                        isLink={true}
                        customCss={'margin-block: 0; margin-left: 2.5rem;'}
                        url={contactUsPage.url}
                        label={removeAmpersand(contactUsPage.title)}
                        small={true}
                    ></Button>
                </Show>
            </Show>
        </StyledCareNavigationShortcuts>
    );
};
