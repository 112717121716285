import { createStore } from 'solid-js/store';
import { gql } from 'graphql-request';
import { Accessor, createMemo, Show, useContext } from 'solid-js';
import { Grid } from '../../grid-system/grid/grid';
import { Section } from '../../grid-system/section/section';
import { ErrorCatcher } from '../../tools/error-catcher';
import { gridSettings } from '../../ui-components/layouts/sidebar.style';
import { EventsContainer } from './events-container';
import { EventsSidebar } from './events-sidebar';
import { EventsProps } from './events-types';
import { fetchEvents } from './helpers/fetch-events';
import { AppContext } from '../../app-context-provider/app-context-provider';
import { useNavigate } from '@solidjs/router';
import createClickHelper from './helpers/filters-handle-on-click-helper';
import { EventData } from '../event/event-types';
import { LoadingPlaceHolder } from '../loading-place-holder/loading-place-holder';
import { SiteDomain } from '../../types/app-state';
import { isOnDemandEvent } from './helpers/is-on-demand-event';

const GET_EVENTS_QUERY = gql`
    query GetEvents(
            $siteId: String,
            $type: [String],
            $tags: [String],
            $limit: Int,
            $fromDate: String,
            $toDate: String
        ) {
        events: resources(
                siteId: $siteId,
                type: $type,
                tags: $tags,
                limit: $limit,
                order: DESC,
                fromDate: $fromDate,
                toDate: $toDate
            ) {
            content
        }
    }
`;

const GET_MIRRORED_EVENTS_KEYS_QUERY = gql`
    query GetMirroredEvents($key: String!) {
        resource(key: $key) {
            content
            key
        }
    }
`;

type SiteEventsObject = {
    siteDomain: SiteDomain;
    siteEvents: Accessor<{ events: { content: EventData; }[] }>;
};

export const Events = (props: EventsProps) => {
    const { siteDomains, createCachedResource, siteInfo } = useContext(AppContext);
    const navigate = useNavigate();
    const [ eventsBySite, setEventsBySite ] = createStore<{ data: SiteEventsObject[]; }>({ data: [] });
    const [ mirroredEventsKeys ] = createCachedResource(GET_MIRRORED_EVENTS_KEYS_QUERY, () => ({ key: '/wp-json/rawb/v1/mirrored-global-events-keys' }), true);
    const [ internationalEvents, setInternationalEvents ] = createStore<{ data: { content: EventData}[]; }>({ data: [] });

    const clickHelper = createClickHelper(navigate);

    const internationalSite = siteDomains.find((site) => site.country === 'international');
    const isInternationalSite = siteInfo.siteId === internationalSite?.siteId;

    const eventsData = fetchEvents({});

    for (const site of siteDomains) {
        const [ siteEvents ] = createCachedResource(GET_EVENTS_QUERY, () => ({ siteId: site.siteId, type: [ 'event' ] }), true) as any;
        
        const siteEventsObject: SiteEventsObject = {
            siteDomain: site,
            siteEvents,
        };

        setEventsBySite('data', (s) => [...s, siteEventsObject]);

        if (site.siteId === internationalSite?.siteId) {            
            setInternationalEvents('data', siteEvents()?.events);
        }
    }

    const sortEvents = (events: EventData[]) => {
        return events
            .filter((event: EventData) => {
                // If there is no eventDate, we assume it is an on-demand event - if it is not, it should be filtered out (bad content)
                return event?.eventDate || isOnDemandEvent(event);
            })
            .sort((a: EventData, b: EventData) => {
                // We sort by date, not eventDate, in order to sort on-demand events simultaneously
                const dateA = new Date(a?.date).getTime();
                const dateB = new Date(b?.date).getTime();
                return dateA - dateB;
            });
    };

    const getFlatlistOfAllEventsFromAllSites = createMemo(() => {
        const loaded = eventsBySite.data.reduce((acc: any, site: any) => {
            return acc && Boolean(site.siteEvents());
        }, true);

        if (!loaded) {
            return [];
        }

        const flatList = eventsBySite.data.reduce((acc: EventData[], site: SiteEventsObject) => {
            const siteEvents = site.siteEvents()?.events.map((event: { content: EventData }) => {
                const baseUrl = site.siteDomain.domains[ siteInfo.target ];
                
                const url = internationalSite?.siteId === site.siteDomain.siteId
                    ? event?.content.url
                    : `${siteInfo.protocol}://${baseUrl}/${event?.content.url}`;
    
                const newEvent = {
                    ...event?.content,
                    url,
                };

                return newEvent;
            });

            return [...acc, ...siteEvents];
        }, []);

        return sortEvents(flatList);
    });

    const filterByAudience = (post: EventData) => {
        switch (props.audience) {
            case 'all': return true;
            case 'patients': return !post?.isHealthcareProfessional;
            case 'hcp': return post?.isHealthcareProfessional;
            default: return true;
        };
    };

    const getCountryEvents = () => {
        const mirroredEvents = getMirroredEvents();

        if (!eventsData()?.length) {
            return mirroredEvents || [];
        }

        // Weave together mirrored events with country events
        const countryEvents = eventsData()?.concat(mirroredEvents);
        return sortEvents(countryEvents);
    };

    const getMirroredEvents = () => {
        const mirrored = mirroredEventsKeys()?.resource?.content;

        if (!mirrored?.length) {
            return [];
        }

        if (!internationalEvents.data?.length) {
            return [];
        }

        return internationalEvents.data
            .map((globalEvent) => {
                return globalEvent.content;
            })
            .filter((content) => {
                return !content.disableMirroringInCountries;
            })
            .filter((content) => {
                return mirrored.find((mirroredEventKey: string) => mirroredEventKey === content.permalink);
            })
            .map((content) => {
                const baseUrl = internationalSite?.domains[ siteInfo.target ];

                return {
                    ...content,
                    url: `${siteInfo.protocol}://${baseUrl}/${content.url}`,
                };
            });
    };

    const filteredEvents = createMemo(() => {
        const events: EventData[] = isInternationalSite ? getFlatlistOfAllEventsFromAllSites() : getCountryEvents();
        const filteredByAudience = events?.filter(filterByAudience);
        return filteredByAudience;
    });
  
    return (
        <ErrorCatcher componentName="Events list">
            <Show when={filteredEvents()} fallback={<LoadingPlaceHolder />}>
                <Section
                    templateShorthand={[12]}
                    widthType={'bgFull'}
                    heightType={'fill'}
                    backgroundType={'color'}
                    backgroundValue={'white'}
                    removeSpacingBlock={true}
                    customCss={'padding-top: 0; padding-bottom: 0;'}
                >
                    <Grid {...gridSettings.container}>
                        <Show when={filteredEvents().length}>
                            <EventsSidebar
                                events={filteredEvents()}
                                labels={props?.labels}
                                clickHelper={clickHelper}
                                isInternationalSite={isInternationalSite}
                            />

                            <EventsContainer
                                events={filteredEvents()}
                                labels={props?.labels}
                                clickHelper={clickHelper}
                                isInternationalSite={isInternationalSite}
                            />
                        </Show>
                    </Grid>
                </Section>
            </Show>
        </ErrorCatcher>
    );
};

Events.parseProps = (atts: any) => {
    return {
        tag: atts.tag,
        labels: atts.labels,
        audience: atts.audience,
    };
};
