import { For, useContext } from 'solid-js';
import { Show } from 'solid-js';
import { ShortcutMenuIcons } from '../shortcuts-menu-icons/shortcuts-menu-icons';
import type { ShortCutMenuLinkItem, ShortcutsMenuMobileProps, ShortcutsMenuProps } from '../../../types/shortcuts-types';
import {
    StyledShortcutsMenuList,
    StyledShortcutsMenuListLinkItem,
    StyledShortcutsMenuListLinkItems,
    StyledShortcutsMenuListHeading,
    StyledShortcutsMobileMenuListLink,
} from './shortcuts-menu-list.styles';
import { AppContext } from '../../../../../app-context-provider/app-context-provider';

import { removeTrailingSlash } from '../../../../../tools/remove-trailing-slash';
import removeAmpersand from '../../../../../tools/remove-ampersand';

export const ShortcutsMenuList = (props: ShortcutsMenuProps & ShortcutsMenuMobileProps) => {

    const { menuStore } = useContext(AppContext);

    const {close} = menuStore;
    
    return (
        <StyledShortcutsMenuList>
            <StyledShortcutsMenuListHeading>
                <div>{removeAmpersand(props.menuHeading)}</div>
            </StyledShortcutsMenuListHeading>
            <StyledShortcutsMenuListLinkItems>
                <For each={props.links}>
                    {(link: ShortCutMenuLinkItem, index) => (
                        <StyledShortcutsMenuListLinkItem onClick={() => close()}>
                            <Show when={link?.icon}>
                                <ShortcutMenuIcons name={link?.icon} />
                            </Show>
                            <StyledShortcutsMobileMenuListLink
                                href={removeTrailingSlash(link?.url) || '/'}
                                ref={(el: HTMLElement) => {
                                    if (index() !== props.links.length - 1) {
                                        return;
                                    }
                                    
                                    if (props.lastElement) {
                                        props.lastElement(el);
                                    }
                                }}
                            >
                                {removeAmpersand(link?.title)}
                            </StyledShortcutsMobileMenuListLink>
                        </StyledShortcutsMenuListLinkItem>
                    )}
                </For>
            </StyledShortcutsMenuListLinkItems>
        </StyledShortcutsMenuList>
    );
};
